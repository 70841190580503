import { RepositoryFactory } from "@/repositories/lisacomunica/RepositoryFactory";

export default {
  methods: {
    initializeEditForm(response) {
      Object.keys(this.form[this.rep]).forEach((form_key) => {
        this.form[this.rep][form_key] = response[form_key];
      });
    },

    setImages(images) {
      this.images = images.map((image) => {
        return {
          title: image.title,
          value: `${process.env.VUE_APP_LC_BASE_URL}/image/stream/${image.id}`,
        };
      });
    },
    onLoadPreset() {
      if (this.form.preset) {
        Object.keys(this.form[this.rep]).forEach((form_key) => {
          if (!this.exceptions.includes(form_key)) {
            this.form[this.rep][form_key] = this.presets.find(
              (preset) => preset.value == this.form.preset
            )[form_key];
          }
        });
      }
    },
    onSelectPreset(value) {
      if (!value) {
        Object.keys(this.form[this.rep]).forEach((form_key) => {
          if (!this.exceptions.includes(form_key)) {
            this.form[this.rep][form_key] = "";
          }
        });
      }
    },
    showPdfPreview() {
      const payload = {
        id: this.id,
        previewTemplate: this.form[this.rep],
      };
      this.isLoading = true;
      this.preview(payload)
        .then((response) => {
          let file = new Blob([response.data], { type: response.data.type });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    showEmailPreview() {
      this.$bvModal.show("showEmailPreview");
    },
    index(queryString, repository = null) {
      const Repo = RepositoryFactory.get(
        repository ? repository : this.repository
      );
      return Repo.index(queryString);
    },
    preset_all() {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.preset_index();
    },
    stream(id) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.stream(id);
    },
    preview(form) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.preview(form);
    },
    delete(id) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.destroy(id);
    },
    show() {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.show(this.id);
    },
    store(form = null) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.store(form ? form : this.form[this.rep]);
    },
    update(form = null) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.update(this.id, form ? form : this.form[this.rep]);
    },
    credit_show(payload) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.credit_show(payload);
    },
  },
};
