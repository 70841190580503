import Email from "@/repositories/lisacomunica/email";
import Emessage from "@/repositories/lisacomunica/emessage";
import Image from "@/repositories/lisacomunica/image";
import Pdf from "@/repositories/lisacomunica/pdf";
import Sms from "@/repositories/lisacomunica/sms";
import Log from "@/repositories/lisacomunica/log";

const repositories = {
  email: Email,
  emessage: Emessage,
  image: Image,
  log: Log,
  pdf: Pdf,
  sms: Sms,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
